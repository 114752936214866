<template>
  <div id="app">
    <Passwords />
  </div>
</template>

<script>
import Passwords from './Passwords.vue'

export default {
  name: 'App',
  components: {
    Passwords
  }
}
</script>
